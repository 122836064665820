import React, { useEffect, useState } from "react";
import axios from "axios";
import "./VendorsForm.css";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";
import { Form, Button, Col, Row } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";

const Vendors = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [vendor, setVendor] = useState({
    id: "",
    businessName: "",
    businessAddress: "",
    phoneNumber: "",
    taxId: "",
    contactName: "",
    contactPhone: "",
    contactEmail: "",
    paymentInfo: "",
    bank: "",
    goodsServices: "",
  });
  const [banks, setBanks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    const fetchBanks = async () => {
      const { data } = await axios.get(`${apiUrl}inventory/banks/`);
      setBanks(data);
    };
    const fetchCategories = async () => {
      const { data } = await axios.get(
        `${apiUrl}inventory/expenses/categories-filter/`
      );
      setCategories(data);
    };

    fetchBanks();
    fetchCategories();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}inventory/vendor/search/?query=${searchTerm}`
      );
      setSearchResults(response.data);
      setShowForm(false);
    } catch (error) {
      console.error(
        "Error searching for vendors",
        error.response?.data || error.message
      );
    }
  };

  const handleNewVendorClick = () => {
    setShowForm(true);
    setSearchResults([]); // Clear search results
    setSearchTerm(""); // Clear search term
    setEditing(false); // Ensure we're not in editing mode
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVendor({ ...vendor, [name]: value });
  };

  const handlePhoneNumberChange = (value) => {
    setVendor({ ...vendor, phoneNumber: value });
  };

  const handleContactPhoneChange = (value) => {
    setVendor({ ...vendor, contactPhone: value });
  };

  const handleCategoryChange = async (e) => {
    const categoryId = e.target.value;
    console.log("categoryId : ", categoryId);
    setVendor({
      ...vendor,
      type_of_gs_category: categoryId,
      type_of_gs_subcategory: "",
    });
    const { data } = await axios.get(
      `${apiUrl}inventory/expenses/subcategories-vendor/?category=${e.target.value}`
    );
    setSubCategories(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const vendorData = {
      business_name: vendor.businessName,
      business_address: vendor.businessAddress || "", // provide an empty string if no address is entered
      phone_number: vendor.phoneNumber,
      tax_id_number: vendor.taxId || "", // provide an empty string if no tax ID is entered
      contact_name: vendor.contactName,
      contact_phone: vendor.contactPhone,
      contact_email: vendor.contactEmail || "", // provide an empty string if no email is entered
      bank_account: vendor.paymentInfo,
      bank: vendor.bank,
      type_of_gs_category: vendor.type_of_gs_category || null, // assuming that you have a state variable for the category
      type_of_gs_subcategory: vendor.type_of_gs_subcategory || null, // assuming that you have a state variable for the subcategory
    };
    const url = editing
      ? `${apiUrl}inventory/vendor/edit/${vendor.id}/`
      : `${apiUrl}inventory/vendor/new/`; // URL depending on add or edit
    const method = editing ? "put" : "post";

    try {
      const response = await axios({
        method,
        url,
        data: vendorData,
        headers: { "Content-Type": "application/json" },
      });
      setSuccessMessage("Information saved successfully!");
      alert("Information saved successfully!");
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
      setVendor({
        businessName: "",
        businessAddress: "",
        phoneNumber: "",
        taxId: "",
        contactName: "",
        contactPhone: "",
        contactEmail: "",
        paymentInfo: "",
        bank: "",
        goodsServices: "",
      });
      setSubCategories([]);
      setShowForm(false);
      if (editing) {
        setSuccessMessage("Information saved successfully!");
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
        setVendor({
          businessName: "",
          businessAddress: "",
          phoneNumber: "",
          taxId: "",
          contactName: "",
          contactPhone: "",
          contactEmail: "",
          paymentInfo: "",
          bank: "",
          goodsServices: "",
        });
        setSubCategories([]);
        setShowForm(false);
      }
    } catch (error) {
      console.error(
        "Error submitting vendor form",
        error.response?.data || error.message
      );
      setSuccessMessage("");
      // Handle the error appropriately
    }
  };

  const handleCancelEdit = () => {
    setVendor({
      businessName: "",
      businessAddress: "",
      phoneNumber: "",
      taxId: "",
      contactName: "",
      contactPhone: "",
      contactEmail: "",
      paymentInfo: "",
      bank: "",
      goodsServices: "",
    });
    setShowForm(false);
    setEditing(false);
    setSuccessMessage("");
  };

  const handleEditVendorClick = async (vendorData) => {
    // Populate the form with the existing data
    if (vendorData.type_of_gs_category) {
      try {
        const { data } = await axios.get(
          `${apiUrl}inventory/expenses/subcategories/?category=${vendorData.type_of_gs_category}`
        );
        setSubCategories(data);
      } catch (error) {
        console.error(
          "Error fetching subcategories",
          error.response?.data || error.message
        );
      }
    }

    setVendor({
      id: vendorData.id,
      businessName: vendorData.business_name,
      businessAddress: vendorData.business_address,
      phoneNumber: vendorData.phone_number,
      taxId: vendorData.tax_id_number,
      contactName: vendorData.contact_name,
      contactPhone: vendorData.contact_phone,
      contactEmail: vendorData.contact_email,
      paymentInfo: vendorData.bank_account,
      bank: vendorData.bank, // You may need to adjust this to use bank ID
      type_of_gs_category: vendorData.type_of_gs_category,
      type_of_gs_subcategory: vendorData.type_of_gs_subcategory,
    });
    setShowForm(true); // Show the form
    setSearchResults([]); // Hide search results
    setEditing(true); // Set editing to true
  };

  const handleDeleteVendorClick = async (vendorId) => {
    if (window.confirm("Are you sure you want to delete this vendor?")) {
      try {
        await axios.delete(`${apiUrl}inventory/vendor/delete/${vendorId}`);
        // Remove the vendor from searchResults or re-fetch the vendors
        setSearchResults((prevResults) =>
          prevResults.filter((vendor) => vendor.id !== vendorId)
        );
        alert("Vendor deleted successfully");
      } catch (error) {
        console.error(
          "Error deleting vendor",
          error.response?.data || error.message
        );
        alert("Failed to delete vendor");
      }
    }
  };

  const renderTable = () => {
    return (
      <table className="mr-2 ml-2">
        <thead>
          <tr>
            <th>Business Name</th>
            <th>Contact Name</th>
            <th>Contact Phone</th>
            <th>Contact Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {searchResults.map((vendor) => (
            <tr key={vendor.id}>
              <td>{vendor.business_name}</td>
              <td>{vendor.contact_name}</td>
              <td>{vendor.contact_phone}</td>
              <td>{vendor.contact_email}</td>
              <td>
                <button
                  className="edit-button mr-2"
                  onClick={() => handleEditVendorClick(vendor)}
                >
                  Edit
                </button>
                <button
                  className="delete-button"
                  onClick={() => handleDeleteVendorClick(vendor.id)}
                >
                  Delete
                </button>

                {/*    <button>Delete</button>  Implement delete functionality */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <div className="vendor-container">
          <div className="vendor-search-bar">
            <input
              type="text"
              placeholder="Search for vendors by name..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button onClick={handleSearch}>Search</button>
            <button onClick={handleNewVendorClick}>Create New Vendor</button>
          </div>

          {showForm ? (
            <div className="vendor-form">
              {successMessage && (
                <div className="success-message">{successMessage}</div>
              )}
              <h1>{editing ? "Edit Vendor" : "Add New Vendor"}</h1>
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="businessName"
                  value={vendor.businessName}
                  onChange={handleChange}
                  placeholder="Business Name"
                  required
                />
                <input
                  type="text"
                  name="businessAddress"
                  value={vendor.businessAddress}
                  onChange={handleChange}
                  placeholder="Business Address (Optional)"
                />
                <Form.Group as={Row} className="mb-3 mt-2 px-5 text-left">
                  <Form.Label>Phone Number</Form.Label>
                  <Col>
                    <PhoneInput
                      international
                      defaultCountry="MX"
                      value={vendor.phoneNumber}
                      onChange={handlePhoneNumberChange}
                      placeholder="Phone Number"
                      required
                    />
                  </Col>
                </Form.Group>
                <input
                  type="text"
                  name="taxID"
                  value={vendor.taxId}
                  onChange={handleChange}
                  placeholder="Tax ID (Optional)"
                />
                <input
                  type="text"
                  name="contactName"
                  value={vendor.contactName}
                  onChange={handleChange}
                  placeholder="Contact Name"
                  required
                />
                <Form.Group as={Row} className="mb-3 mt-2 px-5 text-left">
                  <Form.Label>Contact Phone</Form.Label>
                  <Col>
                    <PhoneInput
                      international
                      defaultCountry="MX"
                      value={vendor.contactPhone}
                      onChange={handleContactPhoneChange}
                      placeholder="Contact Phone"
                      required
                    />
                  </Col>
                </Form.Group>
                <input
                  type="text"
                  name="contactEmail"
                  value={vendor.contactEmail}
                  onChange={handleChange}
                  placeholder="Contact Email (Optional)"
                />
                <input
                  type="text"
                  name="paymentInfo"
                  value={vendor.paymentInfo}
                  onChange={handleChange}
                  placeholder="Cuenta Bancaria/Clabe"
                  required
                />
                <select
                  name="bank"
                  value={vendor.bank}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Bank</option>
                  {banks.map((bank) => (
                    <option key={bank.id} value={bank.id}>
                      {bank.name}
                    </option>
                  ))}
                </select>
                <select
                  name="type_of_gs_category"
                  value={vendor.type_of_gs_category}
                  onChange={handleCategoryChange}
                  required
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
                <select
                  name="type_of_gs_subcategory"
                  value={vendor.type_of_gs_subcategory}
                  onChange={handleChange}
                >
                  <option value="">Select SubCategory</option>
                  {subCategories.map((sub) => (
                    <option key={sub.id} value={sub.id}>
                      {sub.name}
                    </option>
                  ))}
                </select>

                {/* Add more inputs for each field similarly */}
                <button type="submit">
                  {editing ? "Save Edits" : "Add Vendor"}
                </button>
                {editing && (
                  <button type="button" onClick={handleCancelEdit}>
                    Cancel
                  </button>
                )}
              </form>
            </div>
          ) : searchResults.length > 0 ? (
            <div className="vendor-search-results">{renderTable()}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Vendors;
