import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import "./pos.css"; // Importing the CSS file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faMinusCircle,
  faTrashCan,
  faCircleDot,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

import PosHeader from "../PosHeader.js";
import { useSelector, useDispatch } from "react-redux";
import { addToCart, removeFromCart } from "../../actions/cartActions";

const PosSystem = () => {
  const dispatch = useDispatch();
  const shop = useSelector((state) => state.shop);
  const shopId = shop.shopId;
  const shopName = shop.shopName;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState({
    cash: 0,
    card: 0,
    transfer: 0,
  });
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [activePaymentMethod, setActivePaymentMethod] = useState("");
  const [showPaymentInputs, setShowPaymentInputs] = useState({
    cash: false,
    card: false,
    transfer: false,
  });
  const [clientSearchTerm, setClientSearchTerm] = useState("");
  const [clientResults, setClientResults] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [previouslySelectedClient, setPreviouslySelectedClient] =
    useState(null);

  const reduxCartItems = useSelector((state) => state.cart.selectedItems);
  console.log("Cart Items in PosSystem:", reduxCartItems);

  useEffect(() => {
    mergeCartItems(reduxCartItems);
  }, [reduxCartItems]);

  const mergeCartItems = (newItemsFromRedux) => {
    const mergedItems = [...selectedItems];
    newItemsFromRedux.forEach((itemFromRedux) => {
      const existingItemIndex = mergedItems.findIndex(
        (item) => item.uniqueId === itemFromRedux.uniqueId
      );
      if (existingItemIndex > -1) {
        // Update quantity if item already exists
        mergedItems[existingItemIndex].quantity += itemFromRedux.quantity;
      } else {
        // Add new item
        mergedItems.push(itemFromRedux);
      }
    });
    setSelectedItems(mergedItems);
  };

  useEffect(() => {
    // Auto-search when clientSearchTerm has 3 or more characters
    if (clientSearchTerm.length >= 3) {
      handleClientSearch();
    } else {
      setClientResults([]); // Clear results if the search term is too short
    }
  }, [clientSearchTerm]);

  // Function to handle client search
  const handleClientSearch = async () => {
    if (clientSearchTerm.length < 3) {
      setClientResults([]);
      return;
    }
    try {
      const response = await axios.get(
        `${apiUrl}inventory/clients/search/?query=${clientSearchTerm}`
      );
      setClientResults(response.data);
    } catch (error) {
      console.error(
        "Error searching for clients",
        error.response?.data || error.message
      );
    }
  };

  useEffect(() => {
    const clientItem = reduxCartItems.find((item) => item.clientId);
    if (clientItem && clientItem.clientId) {
      if (selectedClientId !== clientItem.clientId) {
        fetchClientDetails(clientItem.clientId);
      }
    }
  }, [reduxCartItems, selectedClientId]);

  const fetchClientDetails = async (clientId) => {
    try {
      const response = await axios.get(
        `${apiUrl}api/client-detail/${clientId}`
      );
      if (response.data) {
        const clientData = response.data;
        handleClientSelect(clientData);
      }
    } catch (error) {
      console.error("Error fetching client details", error);
    }
  };

  useEffect(() => {
    // Apply discounts whenever selectedItems or selectedClientId change
    if (selectedClientId) {
      fetchClientPromotion(selectedClientId);
      fetchBookedPackages(selectedClientId);
    }
  }, [selectedClientId, selectedItems]);

  // Function to handle client selection
  const handleClientSelect = (client) => {
    setSelectedClientId(client.id);
    setPreviouslySelectedClient(client);
    setClientSearchTerm("");
    setClientResults([]);
  };

  const [promotionResponse, setPromotionResponse] = useState(null);
  const [promotionDiscount, setPromotionDiscount] = useState(0);
  const [bookedPackages, setBookedPackages] = useState([]);

  const [isPromotionApplied, setIsPromotionApplied] = useState(false);
  const [appliedPromotionId, setAppliedPromotionId] = useState(null);

  const fetchClientPromotion = async (clientId) => {
    try {
      const response = await axios.get(
        `${apiUrl}payroll/check-client-promotion/${clientId}/`
      );
      setPromotionResponse(response.data);
      console.log("response data : ", response.data)
      if (response.data && response.data.length > 0) {
        const discountPercentage = response.data[0].discount_percentage;
        setPromotionDiscount(discountPercentage);
        setIsPromotionApplied(true);
        setAppliedPromotionId(response.data[0].promotion.id);
      } else {
        setPromotionDiscount(0);
        setIsPromotionApplied(false);
        setAppliedPromotionId(null);
      }
      console.log("promotion discount : ", response.data);
    } catch (error) {
      console.error("Error fetching client promotion:", error);
      setPromotionResponse(null);
      setPromotionDiscount(0);
      setIsPromotionApplied(false);
      setAppliedPromotionId(null);
    }
  };

  const fetchBookedPackages = async (clientId) => {
    try {
      const response = await axios.get(
        `${apiUrl}inventory/check-booked-package/${clientId}/`
      );
      setBookedPackages(response.data);
      console.log("Prepaid packages: ", response.data);
    } catch (error) {
      console.error("Error fetching booked packages:", error);
      setBookedPackages([]);
    }
  };

  useEffect(() => {
    if (bookedPackages.length > 0) {
      applyBookedPackageDiscount();
    }
  }, [bookedPackages]);

  useEffect(() => {
    if (promotionDiscount > 0) {
      applyPromotionDiscount(promotionDiscount);
    }
  }, [promotionDiscount]);

  const applyBookedPackageDiscount = () => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.map((item) => {
        if (item.isService) {
          const packageFound = bookedPackages.find(
            (pkg) => pkg.service_id === item.id
          );
          if (packageFound && packageFound.remaining_services > 0) {
            packageFound.remaining_services -= 1;
            return {
              ...item,
              discount: 100,
              isApplied: true,
            };
          }
        }
        return item;
      })
    );
  };

  const applyPromotionDiscount = (discountPercentage) => {
    let discountApplied = false;
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.map((item) => {
        if (item.isService && !discountApplied && !item.isApplied) {
          discountApplied = true;
          return {
            ...item,
            discount: discountPercentage,
          };
        }
        return item;
      })
    );
  };

  const displaySelectedClient = () => {
    const selectedClient =
      clientResults.find((client) => client.id === selectedClientId) ||
      // In case the clientResults are cleared, use a fallback to maintain the selected client display
      previouslySelectedClient;
    return selectedClient
      ? `${selectedClient.FirstName} ${selectedClient.LastName}`
      : "No client selected";
  };

  const calculateRemainingTotal = () => {
    const grandTotal = calculateGrandTotal();
    return grandTotal - totalPayments;
  };

  const handleSetRemainingAmount = (method) => {
    setPaymentAmount({
      ...paymentAmount,
      [method]: calculateRemainingTotal(),
    });
  };

  const handleSetFullAmount = (method) => {
    setPaymentAmount({
      ...paymentAmount,
      [method]: calculateGrandTotal(),
    });
  };

  const totalPayments = Object.values(paymentAmount).reduce((acc, amount) => {
    // Again, ensure that amount is a number
    const numericAmount =
      typeof amount === "number" ? amount : parseFloat(amount);
    return acc + numericAmount;
  }, 0);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}inventory/search/combined/pos/?query=${searchTerm}`
      );
      const { inventory, services } = response.data;
      //const { inventory } = response.data;
      console.log("search result : ", response.data);
      const combinedResults = [
        ...inventory,
        ...services.map((service) => ({ ...service, isService: true })),
      ];
      setProducts(combinedResults);
      console.log("combinedResults : ", combinedResults);
    } catch (error) {
      console.error(
        "Error searching for vendors",
        error.response?.data || error.message
      );
    }
  };

  const handleProductSelect = (product) => {
    // Create a unique identifier for the product/service
    const uniqueId = product.isService
      ? `service-${product.id}`
      : `product-${product.id}`;

    console.log("product : ", product.quantityStock);

    const cost = product.cost_per_piece || product.cost_per_hundred_grams || 0;

    console.log("cost : ", cost);
    const price = product.price;
    const margin = ((price - cost) / price) * 100;

    // Check if the item already exists in the cart
    const existingItemIndex = selectedItems.findIndex(
      (item) => item.uniqueId === uniqueId
    );

    if (existingItemIndex !== -1) {
      // Found an existing item, now check if it's a product and has stock constraints
      const existingItem = selectedItems[existingItemIndex];
      if (
        !product.isService &&
        existingItem.quantity >= product.quantityStock
      ) {
        // If it's a product and trying to exceed stockQuantity
        alert(
          `Cannot increase ${
            product.productName || "item"
          } quantity. Stock limit reached.`
        );
      } else {
        // If under stockQuantity, or is a service, increment the quantity
        let updatedItems = [...selectedItems];
        updatedItems[existingItemIndex] = {
          ...existingItem,
          quantity: existingItem.quantity + 1,
        };
        setSelectedItems(updatedItems);
      }
    } else if (product.isService || product.quantityStock > 0) {
      // If it's a new item and either a service or a product with stock, add it to the cart
      setSelectedItems([
        ...selectedItems,
        { ...product, quantity: 1, discount: 0, margin, uniqueId },
      ]);
    } else {
      alert(`Cannot add item to cart. No stock available.`);
    }

    // Apply discounts if client is already selected
    if (selectedClientId) {
      fetchClientPromotion(selectedClientId);
      fetchBookedPackages(selectedClientId);
    }
  };

  const updateQuantity = async (id, uniqueId, newQuantity) => {
    const item = selectedItems.find((item) => item.uniqueId === uniqueId);

    if (!item) {
      console.error("Item with uniqueId", uniqueId, "not found");
      return;
    }

    // If the uniqueId indicates a service, update quantity without stock check
    if (uniqueId.includes("service")) {
      setSelectedItems(
        selectedItems.map((item) =>
          item.uniqueId === uniqueId
            ? { ...item, quantity: Math.max(0, newQuantity) }
            : item
        )
      );
    } else if (uniqueId.includes("product")) {
      // For products, check the stock
      const currentStock = await fetchProductStock(item.id);

      if (newQuantity > currentStock) {
        alert("The requested quantity exceeds the available stock.");
      } else {
        setSelectedItems(
          selectedItems.map((item) =>
            item.uniqueId === uniqueId
              ? { ...item, quantity: Math.max(0, newQuantity) }
              : item
          )
        );
      }
    }
  };

  const fetchProductStock = async (productId) => {
    try {
      const response = await axios.get(
        `${apiUrl}inventory/inventory/${productId}/`
      );
      return response.data.quantityStock;
    } catch (error) {
      console.error("Error fetching product stock", error);
      return 0;
    }
  };

  const updateDiscount = (uniqueId, newDiscount) => {
    console.log("uniqueId : ", uniqueId);

    if (uniqueId.includes("service")) {
      // Call function for service
      updateServiceDiscount(uniqueId, newDiscount);
    } else {
      // Call function for other items
      updateOtherDiscount(uniqueId, newDiscount);
    }
  };

  const updateServiceDiscount = (uniqueId, newDiscount) => {
    console.log(
      "Updating discount for service:",
      uniqueId,
      "New discount:",
      newDiscount
    );

    setSelectedItems(
      selectedItems.map((item) =>
        item.uniqueId === uniqueId
          ? { ...item, discount: Math.max(0, Math.min(100, newDiscount)) }
          : item
      )
    );
  };

  const updateOtherDiscount = (uniqueId, newDiscount) => {
    console.log(
      "Updating discount for other item:",
      uniqueId,
      "New discount:",
      newDiscount
    );

    setSelectedItems(
      selectedItems.map((item) => {
        if (item.uniqueId === uniqueId) {
          console.log("Found item to update:", item);
          const maxDiscountAllowed = item.margin;
          console.log("Max discount allowed:", maxDiscountAllowed);
          const discountToApply = Math.min(newDiscount, maxDiscountAllowed);
          console.log("Applying discount:", discountToApply);
          return {
            ...item,
            discount: Math.max(0, Math.min(100, discountToApply)),
          };
        }
        return item;
      })
    );
  };

  const removeItem = (uniqueId) => {
    dispatch(removeFromCart(uniqueId));
    setSelectedItems(
      selectedItems.filter((item) => item.uniqueId !== uniqueId)
    );
  };

  const calculateSubtotal = () => {
    return selectedItems.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
  };

  const calculateGrandTotal = () => {
    return selectedItems.reduce((acc, item) => {
      if (item.isService && item.discount) {
        return (
          acc +
          (item.price - (item.price * item.discount) / 100) * item.quantity
        );
      } else {
        return acc + item.price * item.quantity;
      }
    }, 0);
  };

  useEffect(() => {
    if (promotionResponse && promotionResponse.length > 0) {
      applyPromotionDiscount(promotionResponse[0].discount_percentage);
    }
  }, [promotionResponse]);

  const calculateDiscount = () => {
    const subtotal = calculateSubtotal();
    const grandTotal = calculateGrandTotal();
    return ((subtotal - grandTotal) / subtotal) * 100;
  };

  {
    /*useEffect(() => {
    const subtotal = calculateSubtotal();
    const grandTotal = calculateGrandTotal();
  }, [selectedItems]);*/
  }

  const absoluteDiscount = calculateSubtotal() - calculateGrandTotal();

  const handlePaymentAmountChange = (e, method) => {
    const value = e.target.value;
    const numericValue = value === "" ? 0 : parseFloat(value);
    setPaymentAmount({ ...paymentAmount, [method]: numericValue });
  };

  const handlePaymentMethod = (method) => {
    setActivePaymentMethod(method);
    setShowPaymentInputs({
      cash: false,
      card: false,
      transfer: false,
      [method]: true,
    });
  };

  const paymentSummary = Object.entries(paymentAmount)
    .map(([method, amount]) => {
      const numericAmount =
        typeof amount === "number" ? amount : parseFloat(amount);
      if (numericAmount > 0) {
        return `${
          method.charAt(0).toUpperCase() + method.slice(1)
        }: $${parseFloat(amount).toFixed(2)}`;
      }
      return null;
    })
    .filter(Boolean)
    .join(", ");

  const handleCancelTransaction = () => {
    // Logic to cancel the transaction
    setSelectedItems([]);
    setPaymentAmount({ cash: 0, card: 0, transfer: 0 });
    setTransactionStatus(null);
    // Other cleanup actions as necessary
  };

  const handleCompleteTransaction = () => {
    const totalPaid = Object.values(paymentAmount).reduce(
      (acc, amount) => acc + parseFloat(amount),
      0
    );
    if (totalPaid.toFixed(2) !== calculateGrandTotal().toFixed(2)) {
      alert("The amounts split does not match the grand total.");
    } else {
      selectedItems.forEach((item) => {
        dispatch(removeFromCart(item.uniqueId));
      });
      submitTransaction();
    }
  };

  const generateReceiptNumber = () => {
    const date = new Date();
    const uniqueSuffix = String(date.getTime()).slice(-7); // Last 7 digits of timestamp
    return `${date.getFullYear()}${
      date.getMonth() + 1
    }${date.getDate()}-${uniqueSuffix}`;
  };

  console.log("selectedItems : ", selectedItems);
  const submitTransaction = () => {
    // Prepare data for the API call
    const formattedDate = moment(new Date()).format("YYYY-MM-DDTHH:mm:ss");
    const receiptNumber = generateReceiptNumber();
    const productDetails = selectedItems.map((item) => ({
      id: item.id,
      quantity: item.quantity,
      isService: item.isService,
      isPrepaid: item.isPrepaid,
      isDirect: item.isDirect,
      isAppointment: item.isAppointment,
      SelectedService: item.SelectedService,
      booking_id: item.booking_id,
      pkgId: item.pkgId,
      discount: item.discount,
      price_before_discount: item.price,
      unit_price: item.price - (item.price * item.discount) / 100,
      total_price:
        item.quantity * (item.price - (item.price * item.discount) / 100),
        isPromotion: isPromotionApplied,  // Pass promotion applied flag
        promotion_id: appliedPromotionId 
    }));

    const saleData = {
      date: formattedDate, // assuming today's date for simplicity
      store: shopId, // you need to have this value from your state or context
      total_sales: calculateGrandTotal(),
      cash_amount: paymentAmount.cash,
      card_amount: paymentAmount.card,
      transfer_amount: paymentAmount.transfer,
      receipt_number: receiptNumber,
      products: productDetails,
      cashier: userInfo.id,
      client: selectedClientId || null,
    };

    axios
      .post(`${apiUrl}inventory/sales/process/`, saleData, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      })
      .then((response) => {
        alert("Transaction Successful!");
        setTimeout(() => {
          setTransactionStatus(null);
          setSelectedItems([]);
          setPaymentAmount({ cash: 0, card: 0, transfer: 0 });
          setPreviouslySelectedClient("");
          setClientSearchTerm("");
          setClientResults([]);
          setProducts([]);
        }, 2000);

        setTransactionStatus("Success");
        // Optionally, fetch updated inventory
      })
      .catch((error) => {
        console.error("Transaction error:", error);
        setTransactionStatus("Failed");
      });
  };

  const handlePriceChange = (uniqueId, newPrice) => {
    setSelectedItems(
      selectedItems.map((item) =>
        item.uniqueId === uniqueId
          ? { ...item, price: Math.max(0, parseFloat(newPrice) || 0) }
          : item
      )
    );
  };

  return (
    <div className="row">
      <PosHeader />
      <div className="pos-container">
        <div className="product-selection">
          <input
            className="input-search-pos"
            type="text"
            placeholder="Search for products by name, vendor or SKU"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button onClick={handleSearch}>Search</button>
          <div className="product-grid">
            {products.map((product) => (
              <div
                key={product.id}
                className="product-card"
                onClick={() => handleProductSelect(product)}
              >
                <img
                  //src={product.image}
                  src={`${apiUrl}${product.image}`}
                  alt={product.isService ? product.name : product.productName}
                  className="product-image"
                  style={{ height: "100px" }}
                />
                <div className="product-info">
                  <div className="product-name">
                    {product.isService ? product.name : product.productName}
                  </div>
                  <div className="product-price">${product.price}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Payment Section */}
        <div className="transaction-summary">
          {/* Client Selection Section */}
          <div className="client-selection">
            <input
              className="input-search-client"
              type="text"
              placeholder="Search for client by name or email"
              value={clientSearchTerm}
              onChange={(e) => setClientSearchTerm(e.target.value)}
            />
            <button onClick={handleClientSearch}>Search Clients</button>
            <ul>
              {clientResults.map((client) => (
                <li
                  key={client.id}
                  onClick={() => handleClientSelect(client)}
                  className="client-selection-pos"
                >
                  {client.FirstName} {client.LastName}
                </li>
              ))}
            </ul>
            <div>Client: {displaySelectedClient()}</div>
            {bookedPackages.length > 0 &&
              bookedPackages.some((pkg) => pkg.remaining_services > 0) && (
                <p className="alert alert-success mt-2">
                  100% prepaid discount will apply to up to{" "}
                  {bookedPackages.reduce(
                    (acc, pkg) => acc + pkg.remaining_services,
                    0
                  )}{" "}
                  services.
                </p>
              )}

            {promotionResponse &&
              promotionResponse.length > 0 &&
              promotionResponse.some(
                (promo) => promo.discount_percentage > 0
              ) &&
              promotionResponse.map((promo, index) => (
                <p key={index} className="alert alert-success mt-2">
                  {promo.discount_percentage}% promotion discount will apply to
                  one service.
                </p>
              ))}
          </div>
          <h2>Cart</h2>
          <p>
            Total Items: {selectedItems.length} | Total Quantity:{" "}
            {selectedItems.reduce((acc, item) => acc + item.quantity, 0)}
          </p>
          <hr />
          {selectedItems.map((item) => (
            <div key={item.uniqueId} className="cart-item">
              <div>
                <p>{item.isService ? item.name : item.productName}</p>
                <p>
                $<input
                  type="number"
                  value={item.price}
                  onChange={(e) => handlePriceChange(item.uniqueId, e.target.value)}
                  style={{ width: "80px" }}
                />
              </p>
              </div>
              <div>
                <button
                  onClick={() =>
                    updateQuantity(item.id, item.uniqueId, item.quantity + 1)
                  }
                >
                  <FontAwesomeIcon icon={faPlusCircle} />
                </button>
                <span>{item.quantity}</span>
                <button
                  onClick={() =>
                    updateQuantity(item.id, item.uniqueId, item.quantity - 1)
                  }
                >
                  <FontAwesomeIcon icon={faMinusCircle} />
                </button>
              </div>
              <div>
                <button
                  onClick={() =>
                    updateDiscount(item.uniqueId, item.discount + 1)
                  }
                >
                  <FontAwesomeIcon icon={faPlusCircle} />
                </button>
                <span>{item.discount.toFixed(2)}%</span>
                <button
                  onClick={() =>
                    updateDiscount(item.uniqueId, item.discount - 1)
                  }
                >
                  <FontAwesomeIcon icon={faMinusCircle} />
                </button>
              </div>
              <p>
                Total: $
                {(
                  item.price *
                  item.quantity *
                  (1 - item.discount / 100)
                ).toFixed(2)}
              </p>
              <button onClick={() => removeItem(item.uniqueId)}>
                <FontAwesomeIcon icon={faTrashCan} />
              </button>
            </div>
          ))}
          <hr />
          <div className="summary-section">
            <p>
              <label>Subtotal:</label>{" "}
              <span>${calculateSubtotal().toFixed(2)}</span>
            </p>
            <p>
              <label>
                Discount: <span>{calculateDiscount().toFixed(2)}%</span>
              </label>{" "}
              <span>-${absoluteDiscount.toFixed(2)}</span>
            </p>
            <p>
              <label>Grand Total:</label>{" "}
              <span>${calculateGrandTotal().toFixed(2)}</span>
            </p>
          </div>
          <hr />
          <div className="payment-details">
            <h3>Payment Splits</h3>
            {Object.entries(paymentAmount).map(
              ([method, amount]) =>
                amount > 0 && (
                  <p key={method} className="payment-line">
                    <span className="payment-type">
                      {method.charAt(0).toUpperCase() + method.slice(1)}
                    </span>
                    <span className="payment-amount">${amount.toFixed(2)}</span>
                  </p>
                )
            )}
            <p className="payment-total">
              <strong>Total Payments:</strong>
              <span>${totalPayments.toFixed(2)}</span>
            </p>
            <p>
              {totalPayments.toFixed(2) === calculateGrandTotal().toFixed(2)
                ? "Matches Grand Total"
                : "Does not match Grand Total"}
            </p>
          </div>
          <hr />
          {["cash", "card", "transfer"].map((method) => (
            <div className="payment-method-detail" key={method}>
              {showPaymentInputs[method] && (
                <>
                  <h4>
                    {method.charAt(0).toUpperCase() + method.slice(1)} Payment
                  </h4>
                  <div>
                    <input
                      type="number"
                      value={paymentAmount[method]}
                      onChange={(e) => handlePaymentAmountChange(e, method)}
                      placeholder={`Enter ${method} amount`}
                    />
                    <button
                      onClick={() => handleSetRemainingAmount(method)}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Remaining Amount"
                    >
                      <FontAwesomeIcon icon={faCircleDot} />
                    </button>
                    <button
                      onClick={() => handleSetFullAmount(method)}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Full Amount"
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </button>
                  </div>
                </>
              )}
            </div>
          ))}
          <div className="payment-methods">
            {["cash", "card", "transfer"].map((method) => (
              <div key={method} className="payment-method-button">
                <button onClick={() => handlePaymentMethod(method)}>
                  {method.charAt(0).toUpperCase() + method.slice(1)}
                </button>
              </div>
            ))}
          </div>
          <div className="complete-cancel-buttons-container">
            <button
              className="complete-transaction-button"
              onClick={handleCompleteTransaction}
            >
              Finalize Sale
            </button>
            <button
              className="cancel-transaction-button"
              onClick={handleCancelTransaction}
            >
              Cancel
            </button>
            {transactionStatus && (
              <p>Transaction Status: {transactionStatus}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PosSystem;
